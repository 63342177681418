<template>
    <div class="shop-search">
        <img src="@public/img/vigneron-online/home/background.jpg" />
        <div class="container clearfix">
            
            <div class="vertical-middle">

                <div class="heading-block center">
                    <component :is="titleTag">{{ displayedTitle }}</component>
                </div>
                <div class="landing-wide-form clearfix">
                    <div v-if="redirectionLoading" class="redirection spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                    <div v-if="suggestionsLoading" class="suggestions spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                    <div class="mb-0">
                        <span class="subtitle">{{ $t('shop.shop.search.sub-title') }}</span>
                        <vue-autosuggest
                            v-model="search"
                            :suggestions="shopSuggestions"
                            class=""
                            :get-suggestion-value="getShopSuggestionValue"
                            @selected="onShopSuggestionSelected"
                            @input="onShopInputChange($event)"
                            :input-props="{id: 'search-shop', placeholder:$t('shop.shop.search.form.input_placeholder'), class:'sm-form-control form-control not-dark autosuggest__input', autocomplete:'off'}"
                        >
                            <template slot-scope="{suggestion}">
                                <div class="shop-suggestion">
                                    <div class="logo">
                                        <img v-if="suggestion.item.logo" :src="getStaticURLFromPath(suggestion.item.logo.original.path)" :alt="suggestion.item.name">
                                    </div>
                                    <div class="info">
                                        <span class="name">{{suggestion.item.shop.name}}</span>
                                        <span class="address">{{getAddressFromCompany(suggestion.item.shop.company)}}</span>
                                    </div>
                                    
                                </div>
                            </template>
                        </vue-autosuggest>
                        <div class="example">(Ex: Vins Faller, Domaine Saint Jean)</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    
</template>

<style>

.shop-search {
	position:relative;
	background-image: linear-gradient(-180deg,rgba(0,0,0,.3),rgba(0,0,0,.1));
	height: 450px;
	padding-top:50px;
	padding-bottom:50px;
	overflow-x:hidden;
}

@media (max-width: 767.98px) {
  .shop-search {
	padding-top:5px;
	padding-bottom:5px;
	}
}

.shop-search > img {
	position:absolute;
	top:0px;
	z-index: -1;
	height: 450px;
	max-width: none;
	min-width: 100%;
}

.shop-search h1,
.shop-search h2 {
	font-size:50px;
	font-weight:900;
	color: #ffffff;
	text-shadow: 0 2px 5px rgba(0,0,0,.5)
	/* text-shadow: #FFF 2px 2px, #FFF -2px -2px, #FFF -2px 2px, #FFF 2px -2px; */
}

.shop-search .subtitle {
	display:block;
	text-align: center;
	margin-bottom: 5px;
	font-size: 20px
}


.shop-search .example {
	margin-left:18px;
	margin-top:5px;
}

.suggestions.spinner {
	right: 20px;
    width: 100px;
	padding-left: 2px;
	padding-top:50px;
}

.redirection.spinner {
	width: 100%;
	left: 0px;
	background: white;
	opacity: 50%;
	padding-top:50px;
}

.suggestions.spinner > div {
	width: 14px;
	height: 14px;	
	margin-top:18px;
}

.redirection.spinner > div {
	margin-top:16px;
}

.shop-search .landing-wide-form {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	position:relative;
	padding:15px;
	
}

.shop-search .form-control {
	height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}

.shop-suggestion {
	display:flex;
}

.shop-suggestion .logo {
	width:60px;
	height:60px;
	margin-right: 10px;
}

.shop-suggestion .info {
	display:flex;
	flex-direction: column;
	padding:10px;
}

.shop-suggestion .info > span {
	display:block;
	line-height: 20px;
}

.shop-suggestion .info .name {
	font-weight: bold;
}

.shop-search .autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 5px;
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class SearchShop extends Vue {

	@Prop({
        type: String,
        required: false,
		default: "h2"
    }) readonly titleTag!: "h1"|"h2";

	@Prop({
        type: String,
        required: false,
    }) readonly title!: string;

	get displayedTitle() {
		return this.title ? this.title : this.$t('shop.shop.search.title')
	}

    shopSuggestions:any = [];

	search = "";
	redirectionLoading = false;
	suggestionsLoading = false;

    getShopSuggestionValue(suggestion:any) {
    	return suggestion.item.name;
	}

	onShopSuggestionSelected(suggestion:any) {
		var path = suggestion.item.path;

        // We emit the event
        this.$emit('shop-selected', suggestion.item);
		
		// We display confirmation page
		var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
		// We display loading animation
		this.redirectionLoading = true;  
      	// We redirect to the shop products page
      	this.$router.push({
            name : 'shop-products',
            params : {
                lang : languageCode,
                shopPath :  path
            }
		}).catch(err => {});
	}

	onShopInputChange(text:string) {
		// We need to get the list of addresses based on what has been entered by the end user
		if(text.length < 4) {
			this.shopSuggestions = [];  
			return;
		}

		this.suggestionsLoading = true;
		this.$shop.shop.search(this.search).then((response:any) => {
			this.suggestionsLoading = false;
			if(response.suggestions) {  
				this.shopSuggestions = [{data:response.suggestions}]; 
			}
			else {
				this.shopSuggestions = [];
			}
		});
	}

	getAddressFromCompany(company:any) {
		var address = company.address;
		var city = address.city[0].toUpperCase() +  address.city.slice(1).toLowerCase(); 
		var str = address.addressLines.join(", ") + ", " + address.postalCode + " " + city;
		return str;
	}
    
}
</script>