<template>
	<div class="vignerons content">
		<SearchShop titleTag="h1" :title="$t('vignerons.title')"></SearchShop>
		<div class="section vigneron" v-for="(shopResult, index) in $vigneronOnline_shops" :key="'shop'+index+'_'+shopResult.shop._id">
			<div class="container clearfix">

				<div class="row">

					<div class="col-3">
						<div class="logo">
							<img :src="getShopLogoURL(shopResult)" :alt="shopResult.shop.name">
						</div>
					</div>

					<div class="col-9">
						<h3>{{shopResult.shop.name}}</h3>

						<a v-if="shopResult.shopContent" href="javascript:void(0)" @click="onShopContentSelected(shopResult)" class="button button-border button-rounded button-large noleftmargin">{{ $t('vignerons.button-shop-content') }}</a>
						<a v-if="shopResult.site && shopResult.site.topLevelDomain" :href="getSiteURL(shopResult)" class="button button-border button-rounded button-large noleftmargin">{{ $t('vignerons.button-site', {'domain': shopResult.site.topLevelDomain}) }}</a>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<style>

/* We set a max size for logos */
.vignerons .vigneron .logo {
	max-width:200px;
}

/* We reduce the sections margins */
.vignerons .section.vigneron {
	margin: 30px 0px;
	padding: 30px 0px;
}

</style>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import SearchShop from '../panels/home/SearchShop.vue';
import { redirectToShopPage } from '../../utils/vigneron-online';
import { getPath } from '@igotweb-node-api-utils/misc';

@Component({
  components: {
	  SearchShop
  }
})
export default class Vignerons extends mixins(GenericPage) {

	getShopLogoURL(shopResult:any) {
		if(shopResult.shopContent && shopResult.shopContent.logo) {
			return this.getStaticURLFromPath(shopResult.shopContent.logo.original.path)
		}
		else if(shopResult.site) {
			// We check if we have logo defined on header component
			var logoURL = getPath(shopResult.site, "content.header.props.options.logoURL");
			if(logoURL) {
				return this.getComputedMediaURL(logoURL);
			}
		}
		return "";
	}

	mounted() {
		
	}

	onShopContentSelected(shopResult:any) {
		// We redirect to the products page
        redirectToShopPage('shop-products',this, shopResult.shop);
	}

	getSiteURL(shopResult:any) {
		return "https://" + shopResult.site.domains[0];
	}

}
</script>