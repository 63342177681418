import { render, staticRenderFns } from "./Vignerons.vue?vue&type=template&id=65a11f80"
import script from "./Vignerons.vue?vue&type=script&lang=ts"
export * from "./Vignerons.vue?vue&type=script&lang=ts"
import style0 from "./Vignerons.vue?vue&type=style&index=0&id=65a11f80&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports